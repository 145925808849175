<!--
 * @Description: 回访单管理
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2022-07-15 16:20:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="workNo">
              <el-input
                v-model="searchForm.workNo"
                placeholder="回访单号"
                title="回访单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="reviewStatusCode">
              <e6-vr-select
                v-model="searchForm.reviewStatusCode"
                :data="reviewStatusList"
                placeholder="回访单状态"
                title="回访单状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="reviewType">
              <e6-vr-select
                v-model="searchForm.reviewType"
                :data="visiteTypeList"
                placeholder="回访类型"
                title="回访类型"
                clearable
                :props="{
                  id: 'codeName',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="创建工单" @click="createTask"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span
                v-if="column.fieldName === 'reviewStatus'"
                :class="[
                  'status',
                  row.reviewStatusCode == 3 || row.reviewStatusCode == 4
                    ? 'finish'
                    : row.reviewStatusCode === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row.reviewStatus }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="60">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 创建工单弹框 -->
    <add-task-dialog
      :showAdd="showAdd"
      @closeDialog="handleClose"
    ></add-task-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import addTaskDialog from "@/components/workOrderManage/addTaskDialog";
import { printError } from "@/utils/util";
import { getVisiteTaskList, findDownList } from "@/api";
export default {
  name: "visiteManage",
  data() {
    return {
      showAdd: false, //是否显示创建工单弹框
      searchForm: {
        workNo: "",
        reviewStatusCode: "",
        reviewType: "",
        createdBy: "",
        createTime: [],
        createdStartTime: "", //	创建时间开始时间
        createdEndTime: "", //	创建时间结束时间
        pageIndex: 1,
        pageSize: 20
      },
      reviewStatusList: [], //回访单状态
      visiteTypeList: [], //回访类型下拉框
      loading: false,
      total: 0,
      columnData: [
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "回访单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "workType",
          display: true,
          fieldLabel: "回访单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "reviewStatus",
          display: true,
          fieldLabel: "回访单状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "reviewType",
          display: true,
          fieldLabel: "回访类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeStr",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  mixins: [base, listPage, listPageReszie],
  components: {
    addTaskDialog
  },
  computed: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  watch: {
    //创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdStartTime = val[0] || "";
        this.searchForm.createdEndTime = val[1] || "";
      }
    }
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["reviewStatus", "RevisitType"])];

        let [reviewStatusRes] = await Promise.all(promiseList);
        //回访单状态
        this.reviewStatusList = this.getFreezeResponse(reviewStatusRes, {
          path: "data.reviewStatus"
        });
        //回访类型
        this.visiteTypeList = this.getFreezeResponse(reviewStatusRes, {
          path: "data.RevisitType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //获取回访服务单列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getVisiteTaskList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "visiteManage/visitDetail",
          params: {
            refresh: true,
            workNo: row.workNo
          }
        });
      }
    },
    //创建工单
    createTask() {
      this.showAdd = true;
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
