var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"workNo"}},[_c('el-input',{attrs:{"placeholder":"回访单号","title":"回访单号"},model:{value:(_vm.searchForm.workNo),callback:function ($$v) {_vm.$set(_vm.searchForm, "workNo", $$v)},expression:"searchForm.workNo"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"reviewStatusCode"}},[_c('e6-vr-select',{attrs:{"data":_vm.reviewStatusList,"placeholder":"回访单状态","title":"回访单状态","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.reviewStatusCode),callback:function ($$v) {_vm.$set(_vm.searchForm, "reviewStatusCode", $$v)},expression:"searchForm.reviewStatusCode"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"reviewType"}},[_c('e6-vr-select',{attrs:{"data":_vm.visiteTypeList,"placeholder":"回访类型","title":"回访类型","clearable":"","props":{
                id: 'codeName',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.reviewType),callback:function ($$v) {_vm.$set(_vm.searchForm, "reviewType", $$v)},expression:"searchForm.reviewType"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"createdBy"}},[_c('el-input',{attrs:{"placeholder":"创建人","title":"创建人"},model:{value:(_vm.searchForm.createdBy),callback:function ($$v) {_vm.$set(_vm.searchForm, "createdBy", $$v)},expression:"searchForm.createdBy"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--2",attrs:{"prop":"createTime"}},[_c('e6-date-picker',{ref:"effectiveDatePicker",attrs:{"popper-class":"no-atTheMoment","label":"创建时间","title":"创建时间","picker-options":_vm.pickerOptions('searchForm.createTime'),"default-time":['00:00:00', '23:59:59'],"start-placeholder":"创建时间（始）","end-placeholder":"创建时间（止）"},model:{value:(_vm.searchForm.createTime),callback:function ($$v) {_vm.$set(_vm.searchForm, "createTime", $$v)},expression:"searchForm.createTime"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true},{key:"operateAction",fn:function(){return [_c('i',{staticClass:"e6-icon-add_line",attrs:{"title":"创建工单"},on:{"click":_vm.createTask}})]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"elTable",staticClass:"elTable",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"序号","width":"50","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var row = ref.row;
return [(column.fieldName === 'reviewStatus')?_c('span',{class:[
                'status',
                row.reviewStatusCode == 3 || row.reviewStatusCode == 4
                  ? 'finish'
                  : row.reviewStatusCode === 2
                  ? 'ongoing'
                  : ''
              ]},[_vm._v("\n              "+_vm._s(row.reviewStatus)+"\n            ")]):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})}),_vm._v(" "),_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('e6-td-operate',{attrs:{"data":_vm.getOperateList()},on:{"command":function($event){return _vm.handleOperate($event, scope.row)}}})]}}])})],2)],1),_vm._v(" "),_c('section',{staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1),_vm._v(" "),_c('add-task-dialog',{attrs:{"showAdd":_vm.showAdd},on:{"closeDialog":_vm.handleClose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }